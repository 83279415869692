import { Component,CUSTOM_ELEMENTS_SCHEMA, Input, input, OnInit } from '@angular/core';
import '@maersk-global/mds-components-core/mc-modal';
import { FileInput } from './file-input/file-input.component';
import { AemsFile } from '../../models/aems-file.model';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { BlobService } from '../../services/blob.service';
import { BlobErrorType } from '../../models/blob-error.model';
import { ToastrService } from '../../services/toastr-service';
import {v4} from 'uuid';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AttachmentForm } from '../../form-model/attachment.form.model';


@Component({
  selector: 'app-file-upload',
  standalone: true,
  imports: [FileInput,FileViewerComponent,FilePreviewComponent],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class FileUploadComponent implements OnInit {
@Input() parentForm:FormGroup<AttachmentForm>|undefined;
@Input() readOnly=false;
@Input() inputFiles:AemsFile[]=[];
disableNext:boolean|null=null;
disablePrevious:boolean|null=null;
openFileViewer:boolean=false;
openUploadDialog:boolean=false;
type=input.required<'full' | 'compact'>();
labelForCompact=input<string>();
shopCode=input<string>("");
files:AemsFile[]=[];
selectedFile!:AemsFile;
navDisplayTextForFileViewer="";
constructor(private uploadToBlobService: BlobService,private toastrService: ToastrService,private fb:FormBuilder) { }
  ngOnInit(): void {
   this.files= this.files.concat(this.inputFiles);
   this.parentForm?.addControl('files',this.fb.nonNullable.control<AemsFile[]>(this.inputFiles));
  }
async onFileAttached(event:File[]){
  this.files=this.files.concat(
    event.map((incomingFile) => {
      return {
        file: incomingFile,
        progress$: new BehaviorSubject<number>(0),
        fileUrl: URL.createObjectURL(incomingFile),
        uploadedToBlob: false,
        shopCode:this.shopCode(),
        generatedName:v4()+"."+incomingFile.name.substring(incomingFile.name.lastIndexOf('.') + 1)?.toLowerCase(),
        fileType:incomingFile.type,
        displayName:incomingFile.name
      };
    })
  );
  await this.uploadToBlob(this.files.filter((file) => !file.uploadedToBlob));
  this.parentForm?.controls.files.setValue(this.files.filter(file=>file.uploadedToBlob));
}

private async uploadToBlob(files: AemsFile[]):Promise<any> {
  if (files.length == 0) return;
  return this.uploadToBlobService.uploadFiles(files,this.shopCode()).then((data) => {
    files.forEach((file) => {
      if(file.file){
      var uploadedFiles=this.files.find(f=>f.generatedName==file.generatedName);
      if(uploadedFiles)
      uploadedFiles.uploadedToBlob=true;
    }});
  }).catch((e:BlobErrorType)=>{
    if(e==BlobErrorType.NO_SAS_URL){
      console.error(e);
      this.toastrService.showToastr("Attachment service is down !","error");
    }
    
  });
}
onFileViewerClosed(){
  // Reset the disable buttons
  this.disableNext=false;
  this,this.disablePrevious=false;
  this.openFileViewer=false;
}
onOpenAttachmentClicked(){
  this.openUploadDialog=true;
}


onThumbnailClicked(file:AemsFile){
  this.selectedFile=file
  this.openFileViewer=true;
  let currentIndex=this.files.indexOf(file);
  this.navDisplayTextForFileViewer=`${currentIndex+1} of ${this.files.length}`;
  if(currentIndex==0){
    this.disablePrevious=true;
  }
  if(currentIndex==this.files.length-1){
    this.disableNext=true;
  }
  
}
onMoreItemsClicked(){
  this.selectedFile=this.files[1];
  this.openFileViewer=true;
}
closeUploadDialog(){
  this.openUploadDialog=false;

}
onDeleteFile(file:AemsFile){
  this.files = this.files.filter(f=>f.generatedName!=file.generatedName)
  this.parentForm?.controls.files.setValue(this.files);
  }

  onNextClicked(){
    let currentIndex=this.files.indexOf(this.selectedFile);
    let nextIndex=currentIndex+1;
    this.selectedFile=this.files[nextIndex];
    if(nextIndex==this.files.length-1){
      this.disableNext=true;
    }
    if(this.disablePrevious){
      this.disablePrevious=null;
    }
    this.navDisplayTextForFileViewer=`${nextIndex+1} of ${this.files.length}`;
  }
  onPreviousClicked(){
    let currentIndex=this.files.indexOf(this.selectedFile);
    let prevIndex=currentIndex-1;
    this.selectedFile=this.files[prevIndex];
    if(prevIndex==0){
      this.disablePrevious=true;
    }
    if(this.disableNext){
      this.disableNext=null;
    }
    this.navDisplayTextForFileViewer=`${prevIndex+1} of ${this.files.length}`;
  }
}
