@if(file){
<mc-modal padding="none" fit="small"
  dimension="large" height="90%" [open]="openFileViewer" (closed)="closeFileViewer()">
  @switch (file.fileType) {
  @case("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
  {
  <object [type]="file.fileType" [data]="sanitizer.bypassSecurityTrustResourceUrl(file.fileUrl)"
    style="width:100%;height: 100%">

  </object>
  }
  @case("application/pdf"){

  <object [type]="file.fileType" [data]="sanitizer.bypassSecurityTrustResourceUrl(file.fileUrl)"
    style="width:100%;height: 100%"></object>

  }
  @default{

  <img style="width:100%;height:100%;object-fit: contain;" [src]="sanitizer.bypassSecurityTrustUrl(file.fileUrl)"
    [alt]="file.displayName">
  }
  }
  
  <div slot="secondaryAction" style="display: flex
  ;
      align-items: center;
      gap: 10px;
      position: absolute;
      left: 10px;"> 
    <mc-button [disabled]="disablePrevious" (click)="onPreviousClicked()" label="Button" hiddenlabel fit="medium" icon="arrow-left" appearance="secondary" variant="filled">
    </mc-button>
    <mc-button [disabled]="disableNext" (click)="onNextClicked()" label="Button" hiddenlabel fit="medium" icon="arrow-right" appearance="secondary" variant="filled">
    </mc-button>
    <div style="width:50px;text-align: center;">{{navDisplayText}}</div>
    <div style="width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;">Filename: {{file.displayName}}</div>
  
  </div>
  
  

  <mc-button label="Close" slot="primaryAction" dialogaction="ok" appearance="primary" fit="medium"></mc-button>
  @if(!readOnly){
  <mc-button label="Delete" [loading]="deleting" slot="secondaryAction" variant="filled" appearance="neutral"
    (click)="onDeleteFileClick()" fit="medium"></mc-button>
  }
  <mc-button fit="medium" icon="tray-arrow-down" label="Download" variant="filled" appearance="neutral"
    slot="secondaryAction"  [href]="file.fileUrl" [download]="file.generatedName">Download</mc-button>
</mc-modal>
}